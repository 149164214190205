import React, { useState } from 'react'
import logo from './headerImg/KTRC LOGO-01-02.png'
import './headerstyle.css'
import NavItems from './NavItems'
import { Link, useNavigate } from 'react-router-dom'

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isHoveredItem, setisHoveredItem] = useState('')
    const navigate = useNavigate()

    const handleNavItemClick = (item) => {
        setIsMenuOpen(false)
        if (item != "home") {
            navigate(`/${item}`)
        }else(
            navigate('/')
        )
    }

    const handleHoveredItem = (item) => {
        setisHoveredItem(item)
    }

    return (
        <>
            <header className="shadow-md  px-4   xl:px-44 bg-[#5C5C5C] font-[sans-serif] min-h-[70px]">
                <div className="flex flex-wrap items-center gap-4 ">
                    <Link  to={'/'} >
                        <img src={logo} alt="logo" className="w-40" />
                    </Link>
                    <div className="flex items-center max-lg:ml-auto  lg:order-1">
                        <button id="toggle"
                            onClick={() => setIsMenuOpen(!isMenuOpen)}
                            className="lg:hidden ml-7">
                            <svg className="w-7 h-7" fill="#fff" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
                            </svg>
                        </button>
                    </div>
                    <ul id="collapseMenu"
                        style={{ display: isMenuOpen ? 'block' : 'none' }}
                        className=" lg:!flex max-lg:space-y-2 max-lg:hidden max-lg:py-4 max-lg:w-full lg:ml-auto lg:mr-8">
                        <NavItems name="home" 
                            handleNavItemClick={handleNavItemClick}
                            isHoveredItem={isHoveredItem}
                            handleHoveredItem={handleHoveredItem}
                        >
                            Home
                        </NavItems>
                        <NavItems name="about" 
                            handleNavItemClick={handleNavItemClick}
                            isHoveredItem={isHoveredItem}
                            handleHoveredItem={handleHoveredItem}
                        >
                            About Us
                        </NavItems>
                        <NavItems name="gallery" 
                            handleNavItemClick={handleNavItemClick}
                            isHoveredItem={isHoveredItem}
                            handleHoveredItem={handleHoveredItem}
                        >
                            Gallery
                        </NavItems>
                        <NavItems name="contact" 
                            handleNavItemClick={handleNavItemClick}
                            isHoveredItem={isHoveredItem}
                            handleHoveredItem={handleHoveredItem}
                        >
                            Contact Us
                        </NavItems>
                    </ul>
                </div>
            </header>


        </>
    )
}

export default Header
import React, { useEffect, useState, useRef } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import img1 from './homeImg/GARDEN_C_6 - Photo_02 (2).jpg'
import img2 from './homeImg/C_1 - Photo (6)-01-01.jpg'
import img3 from '../Home/homeImg/c_1 - Photo (3).jpg'
import img4 from '../Home/homeImg/second_floor-01.jpg'

const HeroSection = () => {
    const [showtext, setShowText] = useState(false)
    const [activeSlide, setActiveSlide] = useState(0);

    useEffect(() => {
        setShowText(false);
        setTimeout(() => {
            setShowText(true);
        }, 100); // Adjust the delay as needed
    }, [activeSlide]);

    return (
        <>
            <Splide
                options={{
                    perPage: 1,
                    pagination: true,
                    fixedWidth: '100%',
                    height: "460px",
                    autoplay: true,
                    speed: 1000,
                    type: 'loop',
                }}
                aria-label="My Favorite Images"
                onMoved={(splide, newIndex) => setActiveSlide(newIndex)}
            >
                <SplideSlide >

                    <div className='overlay' />
                    <img className="slide-img" src={img1} alt="Card image cap" />
                    <div className={`slider-text-div   ${showtext && activeSlide === 0 ? 'show' : ''}`} >
                        <div className={`text-white slider-text `}>
                        Transforming outdoor spaces
                        </div>
                    </div>
                </SplideSlide>

                <SplideSlide>
                    <div className='overlay' />
                    <img className="slide-img" src={img2} alt="Card image cap" />
                    <div className={`slider-text-div  ${showtext && activeSlide === 1 ? 'show' : ''} `} >
                        <div className={`text-white slider-text `}>
                        Bringing spaces to life
                        </div>
                    </div>
                </SplideSlide>
                <SplideSlide>
                    <div className='overlay' />
                    <img className="slide-img" src={img3} alt="Card image cap" />
                    <div className={`slider-text-div  ${showtext && activeSlide === 2 ? 'show' : ''} `} >
                        <div className={`text-white slider-text `}>
                        Elevate your vision
                        </div>
                    </div>
                </SplideSlide>
                <SplideSlide>
                    <div className='overlay' />
                    <img className="slide-img" src={img4} alt="Card image cap" />
                    <div className={`slider-text-div  ${showtext && activeSlide === 3 ? 'show' : ''} `} >
                        <div className={`text-white slider-text `}>
                        Step inside your dream home
                        </div>
                    </div>
                </SplideSlide>

            </Splide>
        </>
    )
}

export default HeroSection
import React, { useState } from 'react';

const Tab = ({ id, label, isActive, handleClick }) => (
    <button
        type="button"
        className={` font-roboto-condensed text-[18px] font-bold  px-[14px] py-[7px] inline-flex items-center me-[10px] whitespace-nowrap ${isActive ? 'text-[#54595f] md:border-y-4 border-y-[3px] border-[#33C2FC] ' : ' hover:border-y-4 hover:border-[#33C2FC]  dark:text-gray-400  '}`}
        onClick={() => handleClick(id)}
    >
        {label}
    </button>
);
export default Tab;
import React, { useEffect, useRef, useState, useCallback } from 'react'
import ImageViewer from 'react-simple-image-viewer';

import Ex_c1_photo from './EXTERIOR/02_C_1 - Photo.jpg'
import Ex_c1_photo_2 from './EXTERIOR/02_C_2 - Photo.jpg'
import Ex_c1_photo_3 from './EXTERIOR/02_C_4 - Photo.jpg'
import Ex_c1_photo_4 from './EXTERIOR/03_C_1 - Photo.jpg'
import Ex_c1_photo_5 from './EXTERIOR/C_1 - Photo (2).jpg'
import Ex_c1_photo_6 from './EXTERIOR/c_1 - Photo (3).jpg'
import Ex_c1_photo_7 from './EXTERIOR/C_1 - Photo (4).jpg'
import Ex_c1_photo_8 from './EXTERIOR/C_1 - Photo (5).jpg'
import Ex_c1_photo_9 from './EXTERIOR/C_1 - Photo (6).jpg'
import Ex_c1_photo_10 from './EXTERIOR/C_1 - Photo (7).jpg'
import Ex_c1_photo_11 from './EXTERIOR/c_1 - Photo_02 (2).jpg'
import Ex_c1_photo_12 from './EXTERIOR/C_1 - Photo_02 (3).jpg'
import Ex_c1_photo_13 from './EXTERIOR/c_1 - Photo_02 (4).jpg'
import Ex_c1_photo_14 from './EXTERIOR/C_1 - Photo_02.jpg'
import Ex_c1_photo_15 from './EXTERIOR/C_1 - Photo.jpg'
import Ex_c1_photo_16 from './EXTERIOR/C_1_NIGHT.jpg'
import Ex_c1_photo_17 from './EXTERIOR/C_1.jpg'
import Ex_c1_photo_18 from './EXTERIOR/c_7 - Photo.jpg'
import Ex_c1_photo_19 from './EXTERIOR/c1.jpg'
import Ex_c1_photo_20 from './EXTERIOR/C3_04.jpg'
import Ex_c1_photo_21 from './EXTERIOR/op_01_c_2.jpg'


// Interior Image -
import Int_photo_1 from './INTERIOR/01.jpg'
import Int_photo_2 from './INTERIOR/02_DRAFT_C_1 - Photo.jpg'
import Int_photo_3 from './INTERIOR/02_DRAFT_C_2 - Photo.jpg'
import Int_photo_4 from './INTERIOR/C_1 - Photo (2).jpg'
import Int_photo_5 from './INTERIOR/c_1 - Photo (3).jpg'
import Int_photo_6 from './INTERIOR/c_1 - Photo (4).jpg'
import Int_photo_7 from './INTERIOR/c_1 - Photo (5).jpg'
import Int_photo_8 from './INTERIOR/C_1 - Photo (6).jpg'
import Int_photo_9 from './INTERIOR/C_1 - Photo_02.jpg'
import Int_photo_10 from './INTERIOR/C_1 - Photo.jpg'
import Int_photo_11 from './INTERIOR/C_2 - Photo (2).jpg'
import Int_photo_12 from './INTERIOR/C_2 - Photo (3).jpg'
import Int_photo_13 from './INTERIOR/c_2 - Photo_04.jpg'
import Int_photo_14 from './INTERIOR/C_3 - Photo (2).jpg'
import Int_photo_15 from './INTERIOR/C_3 - Photo.jpg'
import Int_photo_16 from './INTERIOR/C_5 - Photo.jpg'
import Int_photo_17 from './INTERIOR/C_10 - Photo (2).jpg'
import Int_photo_18 from './INTERIOR/C_10 - Photo.jpg'
import Int_photo_19 from './INTERIOR/C1 (2).jpg'
import Int_photo_20 from './INTERIOR/C1.jpg'
import Int_photo_21 from './INTERIOR/c8.jpg'

// LandScape Image -
import Land_photo_1 from './LANDSCAPE/C_1 - Photo.jpg'
import Land_photo_2 from './LANDSCAPE/C_2 - Photo.jpg'
import Land_photo_3 from './LANDSCAPE/C_3 - Photo.jpg'
import Land_photo_4 from './LANDSCAPE/C_4 - Photo.jpg'
import Land_photo_5 from './LANDSCAPE/C_5 - Photo.jpg'
import Land_photo_6 from './LANDSCAPE/C_6 - Photo.jpg'
import Land_photo_7 from './LANDSCAPE/C_7 - Photo.jpg'
import Land_photo_7_ from './LANDSCAPE/C_7 - Photo (2).jpg'
import Land_photo_8 from './LANDSCAPE/C_8 - Photo.jpg'
import Land_photo_9 from './LANDSCAPE/C_9 - Photo.jpg'
import Land_photo_10 from './LANDSCAPE/C_13 - Photo.jpg'
import Land_photo_11 from './LANDSCAPE/DRAFT_C_17 - Photo.jpg'
import Land_photo_12 from './LANDSCAPE/DRAFT_C_18 - Photo.jpg'
import Land_photo_13 from './LANDSCAPE/DRAFT_C_20 - Photo.jpg'
import Land_photo_14 from './LANDSCAPE/garden_02__3 - Photo.jpg'
import Land_photo_15 from './LANDSCAPE/garden_02__6 - Photo.jpg'
import Land_photo_16 from './LANDSCAPE/GARDEN_C_6 - Photo_02.jpg'


// 3d floor plans
import floor_plan_1 from './3D_FLOOR_PLANS/FIRST_FLOOR (2).jpg'
import floor_plan_2 from './3D_FLOOR_PLANS/first_floor.jpg'
import floor_plan_3 from './3D_FLOOR_PLANS/GROUND_FLOOR (2).jpg'
import floor_plan_4 from './3D_FLOOR_PLANS/GROUND_FLOOR (2).jpg'
import floor_plan_5 from './3D_FLOOR_PLANS/second_floor.jpg'



const floorPlan = [
    floor_plan_1, floor_plan_2, floor_plan_3, floor_plan_4, floor_plan_5
]

const exterior = [
    Ex_c1_photo, , Ex_c1_photo_3, Ex_c1_photo_4, Ex_c1_photo_5,
    Ex_c1_photo_6, Ex_c1_photo_7, Ex_c1_photo_8, Ex_c1_photo_9, Ex_c1_photo_10,Ex_c1_photo_2,
    Ex_c1_photo_11, Ex_c1_photo_12, Ex_c1_photo_13, Ex_c1_photo_14, Ex_c1_photo_15,
    Ex_c1_photo_16, Ex_c1_photo_17, Ex_c1_photo_18, Ex_c1_photo_19, Ex_c1_photo_20,
    Ex_c1_photo_21
]

const interior = [
    Int_photo_1, Int_photo_2, Int_photo_3, Int_photo_4, Int_photo_5,
    Int_photo_6, Int_photo_7, Int_photo_8, Int_photo_9, Int_photo_10,
    Int_photo_11, Int_photo_12, Int_photo_13, Int_photo_14, Int_photo_15,
    Int_photo_16, Int_photo_17, Int_photo_18, Int_photo_19, Int_photo_20,
    Int_photo_21
]

const landscape = [
    Land_photo_1,, Land_photo_3, Land_photo_4, Land_photo_5,
    Land_photo_6, Land_photo_7, Land_photo_7_,  Land_photo_2, Land_photo_8, Land_photo_9,
    Land_photo_11, Land_photo_12, Land_photo_13, Land_photo_14, Land_photo_15,
    Land_photo_16
]

const All = [
    Int_photo_1, Int_photo_2, Int_photo_3, Int_photo_4, Int_photo_5,
    Int_photo_6, Int_photo_7, Int_photo_8, Int_photo_9, Int_photo_10,
    Int_photo_11, Int_photo_12, Int_photo_13, Int_photo_14, Int_photo_15,
    Int_photo_16, Int_photo_17, Int_photo_18, Int_photo_19, Int_photo_20,
    Int_photo_21, Land_photo_1, Land_photo_2, Land_photo_3, Land_photo_4, Land_photo_5,
    Land_photo_6, Land_photo_7, Land_photo_7_, Land_photo_8, Land_photo_9,
    Land_photo_11, Land_photo_12, Land_photo_13, Land_photo_14, Land_photo_15,
    Land_photo_16, Ex_c1_photo, Ex_c1_photo_2, Ex_c1_photo_3, Ex_c1_photo_4, Ex_c1_photo_5,
    Ex_c1_photo_6, Ex_c1_photo_7, Ex_c1_photo_8, Ex_c1_photo_9, Ex_c1_photo_10,
    Ex_c1_photo_11, Ex_c1_photo_12, Ex_c1_photo_13, Ex_c1_photo_14, Ex_c1_photo_15,
    Ex_c1_photo_16, Ex_c1_photo_17, Ex_c1_photo_18, Ex_c1_photo_19, Ex_c1_photo_20,
    Ex_c1_photo_21, floor_plan_1, floor_plan_2, floor_plan_3, floor_plan_4, floor_plan_5
]


const useImageViewer = () => {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
        document.body.style.overflow = 'hidden';
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
        document.body.style.overflow = 'auto';
    };

    return { currentImage, isViewerOpen, openImageViewer, closeImageViewer };
};


export const FLoorSection = () => {
    const { currentImage, isViewerOpen, openImageViewer, closeImageViewer } = useImageViewer();
    return (
        <>
            <div className=" grid sm:grid-cols-3">
                {
                    floorPlan?.map((item, index) => (

                        <div key={index + "__Floor"} onClick={() => openImageViewer(index)} className="sm:max-w-sm sm:m-[2px] my-[5px] relative image-container ">
                            <div className='img-overlay' />
                            <img className="tab-img mmax-w-full sm:w-full" src={item} key={index + "__Floor"} />
                            <div className="middle" style={{ fontFamily: "sans-serif" }}>
                                <div className="text-white text-2xl img-text  ">Floor Image</div>
                            </div>

                        </div>

                    ))
                }
            </div >
            {isViewerOpen && (
                <ImageViewer
                    src={floorPlan}
                    currentIndex={currentImage}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                    backgroundStyle={{ backgroundColor: '#000000c9' }}
                />
            )}

        </>
    )
}

export const ExteriorSection = () => {
    const { currentImage, isViewerOpen, openImageViewer, closeImageViewer } = useImageViewer();
    return (
        <>
            <div className=" grid sm:grid-cols-3">
                {
                    exterior?.map((item, index) => (

                        <div key={index + "__Exterior"} onClick={() => openImageViewer(index)} className="sm:max-w-sm sm:m-[2px] my-[5px] relative image-container ">
                            <div className='img-overlay' />
                            <img className="tab-img mmax-w-full sm:w-full" src={item} key={index + "__Floor"} />
                            <div className="middle" style={{ fontFamily: "sans-serif" }}>
                                <div className="text-white text-2xl  ">Exterior Image</div>
                            </div>

                        </div>
                    ))
                }
            </div>
            {isViewerOpen && (
                <ImageViewer
                    src={exterior}
                    currentIndex={currentImage}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                    backgroundStyle={{ backgroundColor: '#000000c9' }}
                />
            )}
        </>
    )
}

export const InteriorSection = () => {
    const { currentImage, isViewerOpen, openImageViewer, closeImageViewer } = useImageViewer();
    return (
        <>
            <div className=" grid sm:grid-cols-3">
                {
                    interior?.map((item, index) => (

                        <div key={index + "__Interior"} onClick={() => openImageViewer(index)} className="sm:max-w-sm sm:m-[2px] my-[5px] relative image-container ">

                            <img className="tab-img mmax-w-full sm:w-full" src={item} key={index + "__Floor"} />
                            <div className='img-overlay' />
                            <div className="middle" style={{ fontFamily: "sans-serif" }}>
                                <div className="text-white text-2xl  "> Interior Image</div>
                            </div>

                        </div>

                    ))
                }
            </div>
            {isViewerOpen && (
                <ImageViewer
                    src={interior}
                    currentIndex={currentImage}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                    backgroundStyle={{ backgroundColor: '#000000c9' }}
                />
            )}
        </>
    )
}

export const LandScapeSection = () => {

    const { currentImage, isViewerOpen, openImageViewer, closeImageViewer } = useImageViewer();
    return (
        <>
            <div className=" grid sm:grid-cols-3">
                {
                    landscape?.map((item, index) => (

                        <div key={index + "__Landscape"} onClick={() => openImageViewer(index)} className="sm:max-w-sm sm:m-[2px] my-[5px] relative image-container ">
                            <div className='img-overlay' />
                            <img className="tab-img mmax-w-full sm:w-full" src={item} key={index + "__Floor"} />
                            <div className="middle" style={{ fontFamily: "sans-serif" }}>
                                <div className="text-white text-2xl  ">Landscape Image</div>
                            </div>

                        </div>

                    ))
                }
            </div>
            {isViewerOpen && (
                <ImageViewer
                    src={landscape}
                    currentIndex={currentImage}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                    backgroundStyle={{ backgroundColor: '#000000c9' }}
                />
            )}
        </>
    )
}

export const AllSection = () => {
    const { currentImage, isViewerOpen, openImageViewer, closeImageViewer } = useImageViewer();

    return (
        <>
            <div className=" grid sm:grid-cols-3">
                {
                    All?.map((item, index) => (

                        <div key={index + "__All"} onClick={() => openImageViewer(index)} className="sm:max-w-sm sm:m-[2px] my-[5px] relative image-container ">
                            <div className='img-overlay' />
                            <img className="tab-img mmax-w-full sm:w-full" src={item} key={index + "__Floor"} />
                            <div className="middle" style={{ fontFamily: "sans-serif" }}>
                                <div className="text-white text-2xl z-10 ">All Image</div>
                            </div>
                        </div>

                    ))
                }
            </div>
            {isViewerOpen && (
                <ImageViewer
                    src={All}
                    currentIndex={currentImage}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                    backgroundStyle={{ backgroundColor: '#000000c9' }}
                />
            )}
        </>
    )
}
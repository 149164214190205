import React, { useState } from 'react';
import Tab from './Tab';
import { AllSection, ExteriorSection, FLoorSection, InteriorSection, LandScapeSection } from './Constant';
import './homestyle.css'

const AllTabs = () => {
    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    return (
        <div className='md:p-5 p-3'>
            <div className="">
                <nav className=" flex justify-center flex-wrap " aria-label="Tabs" role="tablist">
                    <Tab id={1} label="All" isActive={activeTab === 1} handleClick={handleTabClick} />
                    <Tab id={2} label="3D Interior Rendering" isActive={activeTab === 2} handleClick={handleTabClick} />
                    <Tab id={3} label="3D Exterior Rendering" isActive={activeTab === 3} handleClick={handleTabClick} />
                    <Tab id={4} label="3D Landscape" isActive={activeTab === 4} handleClick={handleTabClick} />
                    <Tab id={5} label="3D Floor Plan" isActive={activeTab === 5} handleClick={handleTabClick} />
                    <Tab id={6} label="2D Planning" isActive={activeTab === 6} handleClick={handleTabClick} />
                </nav>
            </div>

            <div className="mt-5 px-4 xl:px-44">
                <div id="horizontal-alignment-1" role="tabpanel" aria-labelledby="horizontal-alignment-item-1" hidden={activeTab !== 1}>
                   <AllSection />
                </div>
                <div id="horizontal-alignment-2" role="tabpanel" aria-labelledby="horizontal-alignment-item-2" hidden={activeTab !== 2}>
                    <InteriorSection />
                </div>
                <div id="horizontal-alignment-3" role="tabpanel" aria-labelledby="horizontal-alignment-item-3" hidden={activeTab !== 3}>
                    <ExteriorSection />
                </div>
                <div id="horizontal-alignment-3" role="tabpanel" aria-labelledby="horizontal-alignment-item-3" hidden={activeTab !== 4}>
                    <LandScapeSection />
                </div>
                <div id="horizontal-alignment-3" role="tabpanel" aria-labelledby="horizontal-alignment-item-3" hidden={activeTab !== 5}>
                    <FLoorSection />
                </div>
                <div id="horizontal-alignment-3" role="tabpanel" aria-labelledby="horizontal-alignment-item-3" hidden={activeTab !== 6}>
                    <p className="text-gray-500 h-20 dark:text-gray-400">
                    </p>
                </div>
            </div>
        </div>
    );
};

export default AllTabs;

import React from 'react'
import { useLocation } from 'react-router-dom'

const FooterItem = (props) => {
    const { name, children, isHoveredItem, activeFooterItem, handleHoveredItem, handleFooterClick } = props
    const location = useLocation()
    const isActive = (location.pathname === '/' && name === 'home') || location.pathname == `/${name}` || (location.pathname == '/gallery' && ["3dInterior", "3dExterior", "3dLand", "2dPlan", "3dFloor"].includes(name))
    const isHovered = name == isHoveredItem
    
    return (
        <>
            <div className="relative"
                onClick={() => handleFooterClick(name)}
                onMouseEnter={() => handleHoveredItem(name)}
                onMouseLeave={() => handleHoveredItem("")}
            >
                <a className={` hover:cursor-pointer font-semibold block text-[18px]  lg:px-5 lg:py-3 py-2`}
                >
                    {children}

                    {(isActive || isHovered) && <div className="active-line bottom-0 absolute w-full h-1 bg-[#33C2FC]"></div>}
                </a>
            </div>
        </>
    )
}

export default FooterItem
import React, { useState } from 'react'
import thumbnail from './homeImg/thumbnail-img.png'

const AllVideoSection = () => {
    const [selectedVideo, setSelectedVideo] = useState('0__V')
    const [videoUrl, setVideoUrl] = useState("SbcN_AiYTPo?si=b3KlRdbl9mUZ-32u")

    const videoUrlArr = [
        "SbcN_AiYTPo?si=b3KlRdbl9mUZ-32u",
        "5BfmX4DNhfI?si=cIoJ8l3KOIsJq9sK",
        "3yimAYhedHY?si=r5e6P91_qN8XWW0c",
        "OkfQ9o4RI7U?si=rXP6rRns2d3sdmAS",
        "YUG5bzmbBGs?si=cdw_gqgoJ1Wk7eqH",
        "cd7zugfHO98?si=BIhcaHFGaQ_M_OrI",
        "cV2tEawcbRM?si=Lq9RujNRtMZM1ElJ",
        "4f3NS4hOkd4?si=fsf15BIZEbZEAWLp",
        "1ifqv_d1Mwg?si=TRC7sphSOamRAKoT",
        "uC-tfxeI0Ts?si=vSQJcPI0ZZwrUEhH",
        "hectO96bBj0?si=XueHcgs47PR8mk8d",
        "_ALi3-Sf8yA?si=oTRwEmFb-7zI-wYT",
        "cqTrfCkLDWY?si=83j7TFQq69uwhiKJ",
        "ETGs8emGleA?si=Uppkhd9mtzuWDPnH",
        "pN3u3DwMsDk?si=hUoEuosmBSsiOwgR",
        "OAFxcUFpXPI?si=e-UytwvKbBlJkDkF",
        "1p0em-I0Fos?si=gBUfnIoyMVdJKyQp",
        "vtMn2C9OUGM?si=Ap0U3rM53KligJ_l",
        "YKRZtVpIA24?si=IEIH6LHcT6V4T8go",
    ];

    const handleVideoUrl = (video, index) => {
        setVideoUrl(video)
        setSelectedVideo(index + "__V")

    }

    return (
        <section className='allvideo-section'>
            <div className='container mx-auto md:grid justify-center'>

                <div className=' w-full block sm:flex  md:px-4   '>

                    <div className="">
                        <iframe
                            className='w-full h-[16rem] sm:w-[400px] lg:h-[420px] lg:w-[750px] '
                            src={`https://www.youtube.com/embed/${videoUrl}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
                        </iframe>
                    </div>

                    <div className='relative ml-[2px]'>
                        <div className="overflow-y-scroll h-[16rem] lg:h-[420px]  w-full ">
                            <div className='flex justify-between align-center py-[10px] px-4  border-b-[1px]'>
                                <p className='font-bold verdana-font text-[16px]  my-auto text-[#7A7A7A]'>Playlist</p>
                                <p className='verdana-font text-[14px] text-[#7A7A7A]'  >20 videos</p>
                            </div>
                            {videoUrlArr.map((video, index) => (
                                <div
                                    className={`px-4 flex w-96 items-center text-sm hover:cursor-pointer hover:bg-[#F0F0F0] ${selectedVideo === (index + "__V") ? 'bg-[#F0F0F0]' : ''}`}
                                    onClick={() => handleVideoUrl(video, index)}
                                    key={index + "__V"}
                                >
                                    <img src={thumbnail} className='video-thumbnail my-[6px]' alt="" />
                                    <i className="fa-solid fa-circle-play play-icon"></i>
                                    <p className='verdana-font text-[14px] text-[#7A7A7A] my-[18px] mx-[8px]'>Sample Video</p>
                                </div>
                            ))}
                        </div>
                        <div className=" bottom-0 w-full absolute left-0  h-12 bg-gradient-to-t from-white to-transparent pointer-events-none"></div>
                    </div>
                </div>
            </div>

        </section>

    )
}

export default AllVideoSection

// "https://youtu.be/SbcN_AiYTPo"
// "https://youtu.be/5BfmX4DNhfI"
// "https://youtu.be/3yimAYhedHY"
// "https://youtu.be/OkfQ9o4RI7U"
// "https://youtu.be/YUG5bzmbBGs"
// "https://youtu.be/cd7zugfHO98"
// "https://youtu.be/cV2tEawcbRM"
// "https://youtu.be/4f3NS4hOkd4"
// "https://youtu.be/1ifqv_d1Mwg"
// "https://youtu.be/uC-tfxeI0Ts"
// "https://youtu.be/hectO96bBj0"
// "https://youtu.be/_ALi3-Sf8yA"
// "https://youtu.be/cqTrfCkLDWY"
// "https://youtu.be/ETGs8emGleA"
// "https://youtu.be/pN3u3DwMsDk"
// "https://youtu.be/OAFxcUFpXPI"
// "https://youtu.be/1p0em-I0Fos"
// "https://youtu.be/vtMn2C9OUGM"
// "https://youtu.be/YKRZtVpIA24"
import React from 'react'
import interior from '../Home/INTERIOR/C_1 - Photo (2).jpg'

const ContactUs = () => {
  return (
    <>
      <div className='relative'>
        <div className='overlay-about' />
        <img src={interior} className='w-full h-[300px] object-cover' alt='' />
        <div className="middle " style={{ fontFamily: "sans-serif", opacity: 1 }}>
          <div className="text-white md:text-[45px] text-3xl font-semibold font-roboto-condensed ">Contact Us</div>
        </div>
      </div>

      <div className='about-second'>
        <div className="2xl:container 2xl:mx-auto    md:py-12  py-9 ">
          <div className="flex flex-col lg:flex-row gap-3 justify-between ">
            <div className="w-full lg:w-1/2 ">
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14724.14502456417!2d75.8637851!3d22.6896942!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd72b5178f79%3A0x82e42d313fe5bf62!2sKTRC%20Skill%20Development%20Services!5e0!3m2!1sen!2sin!4v1712064060584!5m2!1sen!2sin"
                className='w-full h-[16rem] sm:w-full lg:h-[450px]   '
                allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
            </div>
            <div className="w-full lg:w-1/2  md:mt-0  mt-3 flex flex-col ">
              <h1 className="text-3xl lg:text-2xl font-bold leading-9 mb-2 text-gray-800  border-b-2 border-black">SEND US AN EMAIL </h1>
              <form className="">
                <div className="">
                  <label htmlFor="password" className="block my-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
                  <input type="text" id="name" className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-smfocus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  " name="name" placeholder="Enter your name" required />
                </div>
                <div className="">
                  <label htmlFor="email" className="block my-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                  <input type="email" id="email" className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 " placeholder="name@gmail.com" required />
                </div>

                <div className="">
                  <label for="message" className="block my-2 text-sm font-medium text-gray-900 dark:text-white">Message</label>
                  <textarea id="message" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-sm border border-gray-300 0 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white " placeholder="Type here.."></textarea>

                </div>

                <button type="submit" className="text-white my-2 w-full bg-[#077acb] hover:bg-[#336990] focus:ring-4 focus:outline-none focus:ring-[#4490c6] font-medium rounded-smtext-sm px-5 py-2.5 text-center">Send</button>
              </form>


            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactUs
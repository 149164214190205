import React from 'react'
import landscap from '../Home/LANDSCAPE/C_4 - Photo.jpg'
import AllTabs from '../Home/AllTabs'

const Gallery = () => {
  return (
    <>
      <div className='relative'>
        <div className='overlay-about' />
        <img src={landscap} className='w-full h-[300px] object-cover' alt='' />
        <div className="middle " style={{ fontFamily: "sans-serif", opacity: 1 }}>
          <div className="text-white md:text-[45px] text-3xl font-semibold font-roboto-condensed ">Gallery</div>
        </div>
      </div>
      <div className=' mb-12'>
      <AllTabs />
      </div>
    </>
  )
}

export default Gallery
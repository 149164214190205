import React from 'react'
import img1 from '../Home/homeImg/GARDEN_C_6 - Photo_02 (2).jpg'
import Land_photo_6 from '../Home/LANDSCAPE/C_6 - Photo.jpg'
import './aboutstyle.css'
const About = () => {
    return (
        <>
            <div className='relative'>
                <div className='overlay-about' />
                <img src={img1} className='w-full h-[300px] object-cover' alt='' />
                <div className="middle " style={{ fontFamily: "sans-serif", opacity: 1 }}>
                    <div className="text-white md:text-[45px] text-3xl font-semibold font-roboto-condensed ">About Us</div>
                </div>
            </div>

            <div className='about-second'>
                <div className="2xl:container 2xl:mx-auto   md:py-12  py-9 ">
                    <div className="flex flex-col lg:flex-row justify-between gap-8">
                        <div className="w-full lg:w-8/12 ">
                            <img className="w-full h-full" src={Land_photo_6} alt="A group of People" />
                        </div>
                        <div className="w-full lg:w-5/12 flex flex-col justify-center">
                            <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 pb-4">About Us</h1>
                            <p className="font-normal text-base leading-6 text-gray-600 text-justify"> There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humor, or randomized words which don’t look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn’t anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex md:flex-row flex-col about-third  text-white p-10 relative  '>
                <div className='overlay-grid ' />
                <a className="flex flex-col  z-10  border border-gray-200   md:flex-row md:w-1/3  w-full  dark:bg-gray-800 dark:hover:bg-gray-700">

                    <div className="flex flex-col  justify-between text-center p-4 leading-normal">
                        <h5 className="mb-2 text-2xl font-bold tracking-tight  ">OUR MISSION</h5>
                        <p className="mb-3 font-normal  dark:text-gray-400">To offer designer eyewear at a revolutionary price while leading the way for socially conscious businesses.</p>
                    </div>
                </a>
                <a className="flex flex-col z-10 items-center justify-center border border-gray-200  md:flex-row md:w-1/3  w-full  dark:bg-gray-800 dark:hover:bg-gray-700">

                    <div className="flex flex-col  text-center p-4 leading-normal">
                        <h5 className="mb-2 text-2xl font-bold tracking-tight  ">OUR VISION</h5>
                        <p className="mb-3 font-normal  dark:text-gray-400">To create a better design for our clients.</p>
                    </div>
                </a>
                <a className="flex flex-col items-center z-10 border border-gray-200   md:flex-row md:w-1/3  w-full  dark:bg-gray-800 dark:hover:bg-gray-700">

                    <div className="flex flex-col  text-center p-4 leading-normal">
                        <h5 className="mb-2 text-2xl font-bold tracking-tight  ">OUR VALUES</h5>
                        <p className="mb-3 font-normal  dark:text-gray-400">Think Deeply. Iterate as a Lifestyle. Details, Detailed. Design is Everywhere. Integrity.</p>
                    </div>
                </a>

            </div>
        </>
    )
}

export default About
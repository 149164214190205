import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AOS from 'aos';
import 'aos/dist/aos.css';

import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './components/Home/Home';
import { AllSection } from './components/Home/Constant';
import AllVideoSection from './components/Home/AllVideoSection';
import About from './components/About/About';
import Gallery from './components/Gallery/Gallery';
import ContactUs from './components/ContactUs/ContactUs';

// Initialize AOS
AOS.init();
const appRouter = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <> Error  Page not found</>,
        children: [
            {
                path: "/",
                element: <Home />
            },
            {
                path: "/about",
                element: <About />
            },
            {
                path: "/gallery",
                element: <Gallery />
            },
            {
                path: "/contact",
                element: <ContactUs />
            },
            {
                path: "/allvideo",
                element: <AllVideoSection />
            }
        ]
    }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={appRouter} />
);


reportWebVitals();

import React from 'react'
import { useLocation} from 'react-router-dom'

const NavItems = ({ name, handleNavItemClick, children, isHoveredItem, handleHoveredItem }) => {
    const location  = useLocation()
    const isActive =  (location.pathname === '/' && name === 'home')   ||  location.pathname == `/${name}`
    const isHovered = name === isHoveredItem;

    return (
        <li
            className=" max-lg:py-3 px-5 relative font-roboto-condensed "
            onClick={() => handleNavItemClick(name)}
            onMouseEnter={() => handleHoveredItem(name)}
            onMouseLeave={() => handleHoveredItem('')}
        >
            <a
                className={`hover:text-[#33C2FC] hover:cursor-pointer ${(isActive || isHovered) ? 'text-[#33C2FC]' : 'text-white'} font-semibold block text-[20px]`}              
            >
                {children}
            </a>
            {(isActive || isHovered) && <div className="active-line absolute w-full h-1 bg-[#33C2FC] bottom-0"></div>}
        </li>
    );
}

export default NavItems
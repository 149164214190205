import React, { useState } from 'react'
import logo from '../Header/headerImg/KTRC LOGO-01-02.png'
import './footer.css'
import { Link, useNavigate } from 'react-router-dom'
import * as Scroll from 'react-scroll';

import FooterItem from './FooterItem'

const Footer = () => {
    const [activeFooterItem, setActiveFooterItem] = useState('');
    const [isHoveredItem, setisHoveredItem] = useState('')

    const navigate = useNavigate();

    const FooterScrollToTop = () => {
        setTimeout(async () => {
            Scroll.animateScroll.scrollToTop();
        }, 1000)
    }

    const handleFooterClick = (item) => {
        if (item == "3dInterior" || item == "3dExterior" || item == "3dLand" || item == "2dPlan" || item == "3dFloor") {
            setActiveFooterItem('allPhoto')
            navigate('/gallery')
            FooterScrollToTop()
        } else {
            setActiveFooterItem(item)
            if (item != "home") {
                navigate(item)
                FooterScrollToTop()
            } else {
                navigate('/')
                FooterScrollToTop()
            }
        }
    }

    const handleHoveredItem = (item) => {
        setisHoveredItem(item)
    }

    return (
        <>

            <footer className=" bg-[#5C5C5C]  text-surface/75  lg:text-left">

                <div className="footer-section  container mx-auto md:grid justify-center text-white md:text-left">
                    <div className="flex font-roboto-condensed flex-col md:flex-row ">
                        {/* TW Elements section */}
                        <div className="flex-shrink-0 mb-3 md:mb-0 md:w-1/3 md:p-[10px] ">
                            <h6 className="mb-5 flex  items-center lg:justify-center  justify-start">
                                <span className="me-3 ">
                                    <img src={logo} className='w-[70%]' alt="" />
                                </span>
                            </h6>
                            <p className='text-left'>
                                Address :-203, Pearl Business Park, opposite Bholaram Ustad Marg, Bhawarkua, Vishnu Puri Colony, Indore, Madhya Pradesh 452001
                            </p>
                            <p>Mail :- ktrc3dvision@gmail.com</p>
                            <p>Phone  :- +91 91744 98888</p>
                        </div>
                        {/* Products section */}
                        <div className='w-1/2  md:p-[10px] '>
                            <h6 className="md:mb-4  mb-2 flex text-[20px] font-roboto-condensed font-semibold uppercase justify-start">
                                SERVICES
                            </h6>
                            <FooterItem
                                name="3dInterior"
                                activeFooterItem={activeFooterItem}
                                isHoveredItem={isHoveredItem}
                                handleHoveredItem={handleHoveredItem}
                                handleFooterClick={handleFooterClick}
                            >
                                3D Interior Rendering
                            </FooterItem>
                            <FooterItem
                                name="3dExterior"
                                activeFooterItem={activeFooterItem}
                                isHoveredItem={isHoveredItem}
                                handleHoveredItem={handleHoveredItem}
                                handleFooterClick={handleFooterClick}
                            >
                                3D Exterior Rendering
                            </FooterItem>
                            <FooterItem
                                name="3dLand"
                                activeFooterItem={activeFooterItem}
                                isHoveredItem={isHoveredItem}
                                handleHoveredItem={handleHoveredItem}
                                handleFooterClick={handleFooterClick}
                            >
                                3D Landscape
                            </FooterItem>
                            <FooterItem
                                name="2dPlan"
                                activeFooterItem={activeFooterItem}
                                isHoveredItem={isHoveredItem}
                                handleHoveredItem={handleHoveredItem}
                                handleFooterClick={handleFooterClick}
                            >
                                2D Planning
                            </FooterItem>
                            <FooterItem
                                name="3dFloor"
                                activeFooterItem={activeFooterItem}
                                isHoveredItem={isHoveredItem}
                                handleHoveredItem={handleHoveredItem}
                                handleFooterClick={handleFooterClick}
                            >
                                3D Floor Plan
                            </FooterItem>


                        </div>
                        {/* Useful links section */}
                        <div className='w-1/2 mt-2 md:mt-0 md:p-[10px]'>
                            <h6 className="md:mb-4 mb-2 flex text-[20px] uppercase font-semibold  justify-start">
                                Our Link
                            </h6>
                            <FooterItem
                                name="home"
                                activeFooterItem={activeFooterItem}
                                isHoveredItem={isHoveredItem}
                                handleHoveredItem={handleHoveredItem}
                                handleFooterClick={handleFooterClick}
                            >
                                Home
                            </FooterItem>

                            <FooterItem
                                name="about"
                                activeFooterItem={activeFooterItem}
                                isHoveredItem={isHoveredItem}
                                handleHoveredItem={handleHoveredItem}
                                handleFooterClick={handleFooterClick}
                            >
                                About Us
                            </FooterItem>
                            <FooterItem
                                name="gallery"
                                activeFooterItem={activeFooterItem}
                                isHoveredItem={isHoveredItem}
                                handleHoveredItem={handleHoveredItem}
                                handleFooterClick={handleFooterClick}
                            >
                                Gallery
                            </FooterItem>
                            <FooterItem
                                name="contact"
                                activeFooterItem={activeFooterItem}
                                isHoveredItem={isHoveredItem}
                                handleHoveredItem={handleHoveredItem}
                                handleFooterClick={handleFooterClick}
                            >
                                Contact Us
                            </FooterItem>
                        </div>
                        {/* Contact section */}
                        <div className=' w-full md:w-1/2 mt-2 md:mt-0 md:p-[10px]'>
                            <h6 className="mb-4 flex text-[20px]  gap-2 justify-center">
                                <Link to={'https://www.facebook.com/p/KTRC-3D-vision-indore-100065535461050/'} target='blank'>
                                    <i className="fa-brands fa-facebook my-2" style={{ fontSize: 20 }} ></i>
                                </Link>
                                <Link to={'https://www.youtube.com/@ktrc3dvision861'} target='blank'>
                                    <i className="fa-brands fa-youtube my-2" style={{ fontSize: 20 }}></i>
                                </Link>
                               
                                <Link to={'https://www.instagram.com/ktrc3d_vision/'} target='blank'>
                                <i className="fa-brands fa-square-instagram  my-2" style={{ fontSize: 20 }}></i>
                                </Link>
                            </h6>
                            <div className='flex gap-2  justify-center'>

                            </div>
                            <div>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14724.14502456417!2d75.8637851!3d22.6896942!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd72b5178f79%3A0x82e42d313fe5bf62!2sKTRC%20Skill%20Development%20Services!5e0!3m2!1sen!2sin!4v1712064060584!5m2!1sen!2sin"
                                    className='w-full h-[16rem] lg:w-[280px] lg:h-[300px] '
                                    allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />

                            </div>

                        </div>
                    </div>
                </div>
                {/*Copyright section*/}
                <div className="bg-black/5 p-[10px] text-[14px] bg-[#656565] text-white text-center">
                All Rights Reserved. © 2024 Design & Developed By <Link to={'https://ksvsofttech.com/'} target='blank' > KSVSoftTech </Link> 
                </div>
            </footer>


        </>

    )
}

export default Footer
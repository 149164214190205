import React from 'react'
import { Link } from 'react-router-dom'

const YoutubeSection = () => {
    return (
        <>
            <div className='w-full p-4 md:p-8'>
                <iframe
                className='w-full h-[16rem]  lg:h-[800px] '
                 src="https://www.youtube.com/embed/3yimAYhedHY?si=FUKHZXUZHGgLl95Z" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>



                <div className='text-center py-4 px-7 font-roboto-condensed'>
                    <Link to={'/allvideo'}>
                        <button className='view-btn'>
                            View more
                        </button>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default YoutubeSection


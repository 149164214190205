import React from 'react'
import HeroSection from './HeroSection.jsx'
import AllTabs from './AllTabs.jsx'
import YoutubeSection from './YoutubeSection.jsx'


const Home = () => {
    return (
        <>
        <HeroSection />
        <AllTabs />
        <YoutubeSection />
        </>
    )
}

export default Home